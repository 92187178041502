import { PrimaryKey } from '@erp-mobile/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import { message } from 'antd';
import { useMemo } from 'react';
import {
  DistributionSkuItem,
  DistributionTaskData,
} from './distribution-task.dto';
import { Type } from './distribution-task.entity';
import {
  ALLSINGLE,
  PRINT,
  SINGLELIST,
  SINGLE_ORDER,
} from './distribution-task.key';
import { distributionTaskService } from './distribution-task.service';

/**
 * 手动获取任务
 */
export const useSingleDistributionTask = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      type,
      warehouseId,
    }: {
      type: Type;
      warehouseId: PrimaryKey;
    }): Promise<DistributionTaskData | { message: string }> => {
      return await distributionTaskService.getSingleTask(type, warehouseId);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: SINGLELIST,
      });
    },
  });

  return mutation;
};

/**
 * 获取配货任务详情
 */
export const useSingleTaskInfo = (type: Type) => {
  const { data, ...query } = useQuery({
    queryKey: ALLSINGLE,
    queryFn: async () => {
      return distributionTaskService.getSingleTaskDetail(type);
    },
  });

  const listData: DistributionSkuItem[] = useMemo(() => {
    if (!data?.skus) return [];
    return data?.skus.map((item) => ({
      ...item,
      task_id: data.id,
      key: item.custom_text_code
        ? item.sku_code + '@@@' + item.custom_text_code
        : item.sku_code,
      task_code: data.task_code,
    }));
  }, [data]);

  return { ...query, data, listData };
};

/**
 * 单品/多品绑定任务码
 */
export const useBindCode = (type: Type) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: {
      id: PrimaryKey;
      task_code: string;
    }): Promise<void> => {
      await distributionTaskService.bindCode({
        ...data,
        type,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: SINGLELIST,
      });
    },
    // 当绑定任务码时，当前任务失效接口错误刷新详情
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: SINGLELIST,
      });
    },
  });

  return mutation;
};

/**
 * 配货任务执行
 */
export const useExecute = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: {
      id: PrimaryKey;
      quantity: number;
      sku_code: string;
      type: Type;
    }): Promise<void> => {
      await distributionTaskService.singleExecute(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: SINGLELIST,
      });
    },
    // 当接口失败是同样刷新接口，如SKU合并时接口报错需要刷新详情清空任务
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: SINGLELIST,
      });
    },
  });

  return mutation;
};

/**
 * 获取配货任务订单详情
 */
export const useSingleOrderInfo = (
  id: PrimaryKey,
  sku_code: string,
  custom_text_code: string,
  type: Type,
) => {
  const query = useQuery({
    queryKey: SINGLE_ORDER(id, sku_code, custom_text_code),
    queryFn: async () => {
      return distributionTaskService.getSingleOrderInfo({
        id,
        sku_code,
        custom_text_code,
        type,
      });
    },
  });

  return query;
};

/**
 * 配货异常
 */
export const useSingleAbnormal = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: {
      id: PrimaryKey;
      sku_code: string;
      type: Type;
    }): Promise<{ message: string }> => {
      return await distributionTaskService.singleAbnormal(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: SINGLELIST,
      });
    },
  });

  return mutation;
};

/**
 * 配货完成
 */
export const useSingleTaskComplete = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      id,
      type,
    }: {
      id: PrimaryKey;
      type: Type;
    }): Promise<void> => {
      await distributionTaskService.singleTaskComplete({ id, type });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: SINGLELIST,
      });
    },
  });

  return mutation;
};

/**
 * 打印配货单
 */
export const usePrintOrder = (id: PrimaryKey, type: Type) => {
  const query = useQuery({
    queryKey: PRINT(id, type),
    queryFn: async () => {
      return distributionTaskService.printOrder({
        id,
        type,
      });
    },
    enabled: !!id,
  });

  return query;
};
