import { Container } from '@erp-mobile/components';
import { Result } from 'antd-mobile';
import { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useErrorHandler } from './error-handler.hook';

export default function LimitMobile() {
  useErrorHandler();
  const location = useLocation();
  // 请注意  该组件无法 ssr
  const isMobile = useMemo(() => {
    return navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
  }, [location.pathname]);

  if (!isMobile) {
    return (
      <Container>
        <Result status="info" title="请使用移动端登录本系统"></Result>
      </Container>
    );
  }
  return <Outlet></Outlet>;
}
