import { LoaderData } from '@erp-mobile/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLoaderData } from 'react-router-dom';
import { SystemSettings } from './system-settings.dto';
import { systemSettingsService } from './system-settings.service';

export const SYSTEM_SETTINGS = ['WAREHOUSE_SYSTEM_SETTINGS'];

export const useSystemSettings = () => {
  const initialData = useLoaderData() as LoaderData;
  return useQuery({
    queryKey: SYSTEM_SETTINGS,
    queryFn: async () => {
      return systemSettingsService.get();
    },
    initialData: initialData?.systemSettings,
    staleTime: 1000 * 3,
  });
};

export const useUpdateSystemSettings = () => {
  return useMutation({
    mutationFn: async (data: SystemSettings) => {
      return systemSettingsService.update(data);
    },
  });
};
