import { useAutoClearProfile, UserProfileProvider } from '@/features/user';
import { LoaderData } from '@erp-mobile/core';
import { OverlayProvider } from '@erp-mobile/hooks';
import { FC, PropsWithChildren } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';

/**
 * 身份认证拦截守卫
 *
 * 在其子组件加载前会先加载用户信息，用户信息是所有业务逻辑的前提
 *
 * 如果用户信息加载失败则无法进入子页面，身份认证失败会要重新登录，其他错误会展示错误信息并要求用户重试
 */
export const AuthenticationGuard: FC<PropsWithChildren> = () => {
  const data = useLoaderData() as LoaderData;

  useAutoClearProfile();

  return (
    <UserProfileProvider value={data?.user}>
      <OverlayProvider>
        <Outlet></Outlet>
      </OverlayProvider>
    </UserProfileProvider>
  );
};
