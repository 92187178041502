import { useBaseSpeak } from '@erp-mobile/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UpdateBroadcast } from './broadcast.entity';
import { SETTINGS } from './broadcast.key';
import { broadcastService } from './broadcast.service';

/**
 * 语音播报-语速取播报设置
 * @returns
 */
export const useSpeak = () => {
  const { speak, testSpeak, speakSuccess, speakFailure } = useBaseSpeak();

  const broadcastSpeak = (text: string) => {
    speak(text);
  };

  return {
    broadcastSpeak,
    testSpeak,
    speakSuccess,
    speakFailure,
  };
};

export const useBroadcastSettings = () => {
  return useQuery({
    queryKey: SETTINGS,
    queryFn: async () => {
      return broadcastService.get();
    },
  });
};

export const useUpdateBroadcastSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: UpdateBroadcast) => {
      return broadcastService.update(data);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: SETTINGS,
      });
    },
  });
};
