import axios from 'axios';
import {
  AssociatePurchaseOrderPayload,
  AssociatePurchaseOrderResponse,
  LogisticCompanyOption,
} from './sign-tracking-numbers.dto';

export class SingTrackingNumbersService {
  /**
   * 签收快递
   * @param trackingNumbers 快递单号
   */
  async sign(trackingNumbers: string) {
    const { data } = await axios.post('api/logistics/order/sign', {
      logistics_order: trackingNumbers,
    });
    return data;
  }

  /**
   * 快递单号手动关联采购单号
   */
  async associatePurchaseOrder(params: AssociatePurchaseOrderPayload) {
    const { data } = await axios.post<AssociatePurchaseOrderResponse>(
      'api/logistics/order/sign/exceptional',
      params,
    );
    return data;
  }

  /**
   * 物流公司option
   */
  async getLogisticCompanyOptions(params: { title?: string }) {
    const { data } = await axios.get<LogisticCompanyOption[]>(
      'api/purchase/logistic/Company/options',
      {
        params,
      },
    );
    return data;
  }
}

export const singTrackingNumbersService = new SingTrackingNumbersService();
