import { JobModifyPermissions } from '@/features/modify-job';
import { ModifyPositionPermissions } from '@/features/modify-position';
import { InboundStackingPermissions } from '@/features/put-on-shelves';
import { SignaturePermissions } from '@/features/signature';
import { SkuPermission } from '@/features/sku';
import { DistributionPermission } from '@/features/storage/distribution-task';
import { SignTrackingNumbersPermission } from '@/features/storage/sign-tracking-numbers';
import {
  SYSTEM_SETTINGS,
  systemSettingsService,
} from '@/features/system-settings';
import { TransferOutboundPermission } from '@/features/transfer-outbound';
import { userService } from '@/features/user';
import { RouteConfig } from '@erp-mobile/core';
import { queryClient } from './app';
import LimitMobile from './core/limit-mobile';
import { InventoryTaskPermission } from './features/inventory-task';
import { TransferInboundPermission } from './features/transfer-inbound';
import Layouts from './layouts';
import { AuthenticationGuard } from './layouts/authentication-guard';
import { MenuCustomAuthority } from './layouts/authorization/custom-authorization';
import { ErrorFallback } from './layouts/layouts';
import NotFoundPage from './pages/404';

export const routers: RouteConfig[] = [
  {
    element: <LimitMobile></LimitMobile>,
    children: [
      {
        path: '/user',
        children: [
          {
            path: 'login',
            async lazy() {
              const { default: Component } = await import(
                './pages/login/login'
              );
              return { Component };
            },
          },
        ],
      },
      {
        path: '/',
        element: <AuthenticationGuard></AuthenticationGuard>,
        loader: async () => {
          const user = await userService.getUserProfile();
          const systemSettings = await queryClient.fetchQuery({
            queryKey: SYSTEM_SETTINGS,
            queryFn: async () => {
              return systemSettingsService.get();
            },
          });

          return {
            user,
            systemSettings,
          };
        },
        errorElement: <ErrorFallback />,
        children: [
          {
            element: <Layouts></Layouts>,
            children: [
              {
                index: true,
                async lazy() {
                  const { default: Component } = await import(
                    './pages/home/home'
                  );
                  return { Component };
                },
              },
              // 快递签收
              {
                path: 'tracking-numbers-sign',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/sign-tracking-numbers'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [SignTrackingNumbersPermission.Read],
                },
              },
              // 入库上架
              {
                path: 'inbound-stacking',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/inbound-stacking'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [InboundStackingPermissions.Read],
                },
              },
              {
                path: 'multi-distribution',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/multi-distribution'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [DistributionPermission.MultiRead],
                },
              },
              {
                path: 'single-distribution',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/single-distribution/single-distribution'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [DistributionPermission.SingleRead],
                },
              },
              // 爆款配货
              {
                path: 'hot-distribution',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/hot-distribution'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [DistributionPermission.爆款配货],
                },
              },
              {
                path: 'modify-position',
                children: [
                  // 手动修改仓位
                  {
                    path: 'manual',
                    handle: {
                      authorities: [ModifyPositionPermissions.Read],
                    },
                    async lazy() {
                      const { default: Component } = await import(
                        './pages/modify-position/manual/manual'
                      );
                      return { Component };
                    },
                  },
                  // 任务修改仓位
                  {
                    path: 'modify',
                    handle: {
                      authorities: [JobModifyPermissions.Read],
                    },
                    async lazy() {
                      const { default: Component } = await import(
                        './pages/modify-position/modify'
                      );
                      return { Component };
                    },
                  },
                ],
              },
              // 调拨出库
              {
                path: 'transfer-outbound',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/transfer-outbound'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [TransferOutboundPermission.Read],
                },
              },
              {
                path: 'storage-in/signature',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/signature/signature'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [SignaturePermissions.Read],
                },
              },
              {
                path: 'sku-info',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/sku-info'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [SkuPermission.Read],
                },
              },
              {
                path: 'transfer-inbound',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/transfer-inbound'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [TransferInboundPermission.调拨入库],
                  customAuthority: MenuCustomAuthority.调拨签收流程2,
                },
              },
              {
                path: 'inventory-task',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/inventory-task'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [InventoryTaskPermission.任务盘点],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/*',
        element: <NotFoundPage></NotFoundPage>,
      },
    ],
  },
];
