import axios from 'axios';
import { FinishTaskPayload, InventoryTaskDto } from './inventory-task.dto';

class InventoryTaskService {
  async getTaskList(
    warehouse_id?: number,
  ): Promise<InventoryTaskDto | undefined> {
    if (!warehouse_id) {
      return void 0;
    }

    const { data } = await axios.get('api/inventories_task', {
      params: { warehouse_id },
    });
    return data;
  }

  async finishTask(payload: FinishTaskPayload): Promise<void> {
    const { id, real_stock } = payload;

    await axios.post(`api/inventories_task/done/${id}`, {
      real_stock,
    });
  }

  /**
   * 获取盘点任务用户上次获取的仓库
   * @returns 仓库id
   */
  async getWarehouseId(): Promise<string | undefined> {
    const { data } = await axios.get<string[]>(
      `api/inventories_task/default/warehouse`,
    );
    return data?.[0] ?? '';
  }
}

export const inventoryTaskService = new InventoryTaskService();
