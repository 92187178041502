import { useVoiceOptions } from '@/features/broadcast';
import { useOverlayRef } from '@erp-mobile/hooks';
import { CheckList, ErrorBlock, Popup } from 'antd-mobile';
import { useState } from 'react';

export const VoicesSheet = ({
  voices,
  onChance,
}: {
  voices: string;
  onChance: (voices: string) => void;
}) => {
  const { sortCnVoiceOptions } = useVoiceOptions();
  const overlayRef = useOverlayRef();
  const [value, setValue] = useState<string>(voices);

  const onClose = () => {
    overlayRef.close();
  };

  return (
    <Popup
      visible
      onMaskClick={onClose}
      onClose={onClose}
      bodyStyle={{ maxHeight: '75vh' }}
    >
      <div>
        <div className="text-base font-bold text-center py-4">
          请选择一个语言包
        </div>
        <div
          className="overflow-y-scroll"
          style={{ maxHeight: 'calc(75vh - 56px)' }}
        >
          {sortCnVoiceOptions.length > 0 ? (
            <CheckList
              value={value ? [voices] : []}
              onChange={(val) => {
                const newVoices = val?.[0] ?? '';
                setValue(newVoices);

                if (newVoices) {
                  onClose();
                  onChance(newVoices);
                }
              }}
            >
              {sortCnVoiceOptions.map((column) => {
                return (
                  <CheckList.Item key={column.name} value={column.name}>
                    {column.name}
                  </CheckList.Item>
                );
              })}
            </CheckList>
          ) : (
            <div>
              <ErrorBlock status="empty" description="" title="暂无语言包" />
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};
