import axios from 'axios';
import type { Broadcast } from './broadcast.dto';
import type { UpdateBroadcast } from './broadcast.entity'

export class BroadcastService {
  /**
   * 获取播报设置
   */
  async get(): Promise<Broadcast> {
    const { data } = await axios.get<Broadcast>(
      'api/outbound/broadcast'
    );

    return data;
  }

  /**
   * 更新播报设置
   */
  async update(data: UpdateBroadcast): Promise<void> {
    await axios.put('api/outbound/broadcast', data);
  }

}

export const broadcastService = new BroadcastService();
