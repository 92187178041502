import { FocusEventHandler } from 'react';

/**
 * 在输入框获取焦点时，通过设置 input 的 readonly 属性避免弹出键盘
 * @param e input focus event
 */
export const noKeyboardOnFocus: FocusEventHandler<HTMLInputElement> = (e) => {
  e.target.setAttribute('readonly', 'readonly');
  setTimeout(() => {
    e.target.removeAttribute('readonly');
  }, 100);
};
