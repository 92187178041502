import axios from 'axios';
import type { PutOnShelvesItem } from './put-on-shelves.dto';
import { ChangePosition, FinishedInbound } from './put-on-shelves.entity';

export class PutOnShelvesService {
  /**
   * 获取上架任务列表
   */
  async getAll(task_code?: string): Promise<PutOnShelvesItem[]> {
    const { data } = await axios.get<PutOnShelvesItem[]>(
      'api/inbound/stacking',
      {
        params: {
          task_code,
        },
      },
    );

    return data;
  }

  /**
   * 仓位调整
   */
  async changePosition(data: ChangePosition): Promise<void> {
    await axios.put('api/inbound/warehouse', data);
  }

  /**
   * 完成上架
   */
  async finished({ id, ...data }: FinishedInbound): Promise<void> {
    await axios.post(`api/inbound/${id}/stacking`, data);
  }
}

export const putOnShelvesService = new PutOnShelvesService();
