import { useBroadcastSettings } from '@/features/broadcast';
import { useOverlay } from '@erp-mobile/hooks';
import { Button, Space } from 'antd-mobile';
import { SoundOutline } from 'antd-mobile-icons';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { BoradcastDialog } from './broadcast-dialog';
import { Iconfont } from './iconfont';
import S from './page-header.module.less';

interface PageHeaderProps {
  title: string;
  onBack?: () => void;
  noBroadcast?: boolean;
}

export const PageHeader = (props: PageHeaderProps) => {
  const navigate = useNavigate();
  const [openOverlay] = useOverlay();
  const { data } = useBroadcastSettings();

  // 默认回到首页
  const handleClickBack = () => {
    if (props.onBack) {
      props.onBack();
    } else {
      navigate('/');
    }
  };

  const onBroadcast = () => {
    openOverlay(<BoradcastDialog />);
  };

  return (
    <div className="relative px-4 py-2 flex items-center justify-center">
      <Button
        className={clsx(S.override, 'left-5')}
        fill="none"
        onClick={handleClickBack}
      >
        <Iconfont name="fanhui" size={22} />
      </Button>
      <div className="text-center max-w-[60%] text-zinc-800 text-lg font-bold leading-relaxed truncate">
        {props.title}
      </div>
      {props.noBroadcast ? null : (
        <Button
          className={clsx(S.override, 'right-5')}
          fill="none"
          onClick={onBroadcast}
          style={{ width: 48 }}
        >
          <Space justify="end" style={{ '--gap': '4px' }}>
            <SoundOutline />
            <span className="text-xs">{data?.speed ?? '1.0'}</span>
          </Space>
        </Button>
      )}
    </div>
  );
};
