import { useMutation } from '@tanstack/react-query';
import { SkuParams } from './sku.dto';
import skuService from './sku.service';

export const useSkuList = () => {
  return useMutation({
    mutationFn: async (data: SkuParams) => {
      return await skuService.getSkuList(data);
    },
  });
};
