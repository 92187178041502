import {
  useBroadcastSettings,
  useLanguageSettings,
  useSpeak,
  useUpdateBroadcastSettings,
} from '@/features/broadcast';
import { useOverlay, useOverlayRef } from '@erp-mobile/hooks';
import { Dialog, Divider, Selector, Switch } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';
import { useEffect, useState } from 'react';
import S from './broadcast-dialog.module.less';
import { snackbar } from './snackbar';
import { VoicesSheet } from './voices-sheet';

const options = [
  { label: '0.25', value: 0.25 },
  { label: '0.5', value: 0.5 },
  { label: '0.75', value: 0.75 },
  { label: '1.0', value: 1 },
  { label: '1.25', value: 1.25 },
  { label: '1.5', value: 1.5 },
  { label: '1.75', value: 1.75 },
  { label: '2.0', value: 2 },
  { label: '2.25', value: 2.25 },
  { label: '2.75', value: 2.75 },
  { label: '3.0', value: 3 },
];

export const BoradcastDialog = () => {
  const overlayRef = useOverlayRef();
  const [openOverlay] = useOverlay();
  const [value, setValue] = useState<number>(0);
  // 合成语音设置
  const [settings, setSettings] = useLanguageSettings();
  const [isSynthesis, setIsSynthesis] = useState<boolean>(
    !!settings.is_synthesis,
  );
  // 语言包
  const { testSpeak } = useSpeak();

  const { data } = useBroadcastSettings();
  const update = useUpdateBroadcastSettings();

  // 低版本浏览器不兼容
  const isNotSupport = window.SpeechSynthesisUtterance === undefined;

  useEffect(() => {
    if (data) {
      setValue(data?.speed);
    }
  }, [data]);

  const onClose = () => {
    overlayRef.close();
  };

  const onConfirm = async () => {
    // 更新语音播报设置（本地）
    setSettings({ ...settings, is_synthesis: isSynthesis });
    // 更新语音播报速率
    await update.mutateAsync({
      // 关闭智能播报时，取原速率，默认为1
      speed: !isSynthesis ? data?.speed ?? 1 : value,
      switch: data?.switch,
    });
    snackbar.success('播报更新成功');
    overlayRef.close();
  };

  const onPickLang = () => {
    openOverlay(
      <VoicesSheet
        voices={settings.voices ?? ''}
        onChance={(val) => {
          console.log(val);
          setSettings({ ...settings, voices: val });
        }}
      />,
    );
  };

  const onTestSpeak = () => {
    settings.voices && testSpeak(settings.voices);
  };

  return (
    <Dialog
      visible
      className={S.container}
      bodyClassName="px-0"
      title={<div className="text-base font-bold">播报设置</div>}
      content={
        <div>
          <div className="flex items-center justify-between px-2">
            <div className="text-sm">智能语音播报</div>
            <Switch
              checked={!isNotSupport && isSynthesis}
              disabled={isNotSupport}
              onChange={(checked) => setIsSynthesis(checked)}
            />

            {isNotSupport ? (
              <div className="text-xs text-gray-body">浏览器不支持</div>
            ) : null}
          </div>
          {isSynthesis ? (
            <>
              <Divider className="my-2" />
              <div className="flex items-center justify-between px-2">
                <div className="flex items-center">
                  <div className="text-sm">语言包</div>
                  {settings.voices ? (
                    <div
                      onClick={onTestSpeak}
                      className="text-sm text-primary ml-2"
                    >
                      测试
                    </div>
                  ) : null}
                </div>
                <div className="flex items-center">
                  <div className="text-xs" onClick={onPickLang}>
                    {settings.voices ?? '选择一个语言'}
                  </div>
                  <RightOutline />
                </div>
              </div>
              <div className="px-2 mt-2">
                <div className="text-sm mb-4">语音播报速率</div>
                <Selector<number>
                  options={options}
                  className={S.override}
                  value={[value]}
                  onChange={(arr) => setValue(arr[0])}
                />
              </div>
            </>
          ) : null}
        </div>
      }
      closeOnMaskClick
      onClose={onClose}
      actions={[
        [
          {
            key: 'cancel',
            text: '取消',
            danger: true,
            onClick: onClose,
          },
          {
            key: 'confirm',
            text: '确定',
            onClick: onConfirm,
          },
        ],
      ]}
    ></Dialog>
  );
};
