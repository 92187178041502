import { Iconfont } from '@erp-mobile/components';
import { CheckList, Popup } from 'antd-mobile';
import { useRef, useState } from 'react';

interface Props {
  value: number | null;
  onChange: (val: number) => void;
  disabled?: boolean;
  options: Array<{
    id: number;
    title: string;
  }>;
}

export const WarehousePicker: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const warehouse = props.options?.find(
    (i) => props.value && i.id === +props.value,
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  const setScrollIntoView = () => {
    if (containerRef.current) {
      const checkedElement = containerRef.current.getElementsByClassName(
        'adm-check-list-item-active',
      );
      if (checkedElement[0]) {
        checkedElement[0].scrollIntoView();
      }
    }
  };

  const onOpenPicker = () => {
    if (props.disabled) {
      return;
    }
    setVisible(true);
  };

  const onClosePicker = () => {
    setVisible(false);
  };

  return (
    <>
      <div
        style={{ opacity: props.disabled ? 0.5 : 1 }}
        className="h-9 flex items-center max-w-[110px]"
        onClick={onOpenPicker}
      >
        <div className="text-center truncate text-[#333] text-xs">
          {warehouse?.title || '请选择一个仓库'}
        </div>
        <div className="w-5 h-5 flex items-center justify-center">
          <Iconfont name="zhankai-shi" size={20} />
        </div>
      </div>
      <Popup
        visible={visible}
        onMaskClick={onClosePicker}
        onClose={onClosePicker}
        afterShow={setScrollIntoView}
        afterClose={setScrollIntoView}
        bodyStyle={{ maxHeight: '75vh' }}
      >
        <div>
          <div className="text-base font-bold text-center py-4">
            请选择一个仓库
          </div>
          <div
            ref={containerRef}
            className="overflow-y-scroll"
            style={{ maxHeight: 'calc(75vh - 56px)' }}
          >
            <CheckList
              value={props.value ? [props.value.toString()] : []}
              onChange={(val) => {
                props.onChange(Number(val[0]));
                if (val[0]) {
                  onClosePicker();
                }
              }}
            >
              {props.options.map((column) => {
                return (
                  <CheckList.Item key={column.id} value={`${column.id}`}>
                    {column.title}
                  </CheckList.Item>
                );
              })}
            </CheckList>
          </div>
        </div>
      </Popup>
    </>
  );
};
