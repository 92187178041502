import axios from 'axios';
import {
  PasswordCredentialDto,
  TokenDto,
  UserProfile,
  UserRole,
} from './user.dto';

export class UserService {
  private TOKEN_KEY = 'token';
  private PASSWORD_KEY = 'PASSWORD';
  private PHONE_KEY = 'PHONE';

  /**
   * 登录
   * @param dto 用户密码凭证
   * @returns
   */
  async login(dto: PasswordCredentialDto) {
    const { data } = await axios.post<TokenDto>('api/auth/login', dto);
    return data;
  }

  /**
   * 获取用户信息
   *
   * @returns 用户信息
   */
  async getUserProfile() {
    const { data } = await axios.post<UserProfile>('api/me');

    return data;
  }

  async logout() {
    if (!this.hasToken()) {
      return;
    }

    await axios.post('api/auth/logout');
  }

  /**
   * 获取用户下所有角色列表
   */
  async getRoles(): Promise<UserRole[]> {
    const { data } = await axios.get<UserRole[]>('api/roles');
    return data;
  }

  hasToken(): boolean {
    return !!this.getToken();
  }

  setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY) || '';
  }

  setPassword(pwd: string): void {
    localStorage.setItem(this.PASSWORD_KEY, pwd);
  }

  getPassword(): string {
    return localStorage.getItem(this.PASSWORD_KEY) || '';
  }

  setPhone(phone: string): void {
    localStorage.setItem(this.PHONE_KEY, phone);
  }

  getPhone(): string {
    return localStorage.getItem(this.PHONE_KEY) || '';
  }

  clearToken() {
    this.setToken('');
  }
}

export const userService = new UserService();
