import axios from 'axios';
import { SkuDto, SkuParams } from './sku.dto';

export class SkuService {
  async getSkuList(params: SkuParams): Promise<SkuDto[]> {
    if (!params.title && !params.position_code && !params.sku_code) {
      return [];
    }

    const { data } = await axios.post<{ data: SkuDto[] }>(
      `api/skus/list_info`,
      params,
    );

    return data?.data || [];
  }
}

const skuService = new SkuService();
export default skuService;
