import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useWarehouseOptions } from '../warehouse-setting';
import { CompleteJobPayload, GetModifyJobPayload } from './modify-job.dto';
import { JOB_WAREHOUSE_ID_KEY } from './modify-job.key';
import { modifyJobService } from './modify-job.service';

export const useGetJob = () => {
  return useMutation({
    mutationFn: async (params: GetModifyJobPayload) => {
      return await modifyJobService.getModifyJob(params);
    },
  });
};

export const useMutationCompleteJob = () => {
  return useMutation({
    mutationFn: async (data: CompleteJobPayload) => {
      return await modifyJobService.doModifyJob(data);
    },
  });
};

export const useJobWarehouseId = () => {
  const [warehouseId, setWarehouseId] = useState<string>(
    localStorage.getItem(JOB_WAREHOUSE_ID_KEY) || '',
  );
  const { options } = useWarehouseOptions({
    is_data_permissions: '2',
    is_publish: '1',
  });

  useEffect(() => {
    if (warehouseId && options) {
      const warehouse = options?.find(
        (i) => warehouseId && i.id === +warehouseId,
      );
      // 仓库不存在。或取消了权限，需要清空
      if (!warehouse) {
        setWarehouseId('');
      }
    }
  }, [warehouseId, options]);

  useEffect(() => {
    // 保存
    localStorage.setItem(JOB_WAREHOUSE_ID_KEY, warehouseId || '');
  }, [warehouseId]);

  return {
    warehouseId,
    setWarehouseId,
    options,
  };
};
