import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { OverlayProvider } from '@erp-mobile/hooks';
import { SnackbarProvider } from 'notistack';
import { setupAxios } from './core';
import { routers } from './router';

setupAxios();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 关闭在窗口获取焦点的时候刷新数据
      refetchOnWindowFocus: false,
      // 关闭自动重试
      retry: false,
      onError(err) {
        Promise.reject(err);
      },
    },
  },
});

const router = createBrowserRouter(routers);

export function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <SnackbarProvider
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableWindowBlurListener
        dense
      />
      <QueryClientProvider client={queryClient}>
        <OverlayProvider>
          <RouterProvider router={router}></RouterProvider>
        </OverlayProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
