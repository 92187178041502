import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { useEffect, useMemo, useState } from 'react';
import { LanguageSettings } from './broadcast-setting-schema';

const printSettingsAtom = atomWithStorage<LanguageSettings>(
  'WMS_SPEAK_LANGUAGE_SETTINGS',
  { is_synthesis: false },
);

export const useLanguageSettings = () => {
  const [settings, setSettings] = useAtom(printSettingsAtom);

  return [
    settings,
    (settings: LanguageSettings) => {
      setSettings(settings);
    },
  ] as const;
};

export const useVoiceOptions = () => {
  // 语言包
  const [voicesOptions, setVoicesOptions] = useState<SpeechSynthesisVoice[]>(
    [],
  );

  useEffect(() => {
    const synth = window.speechSynthesis;
    const handleVoicesChange = () => {
      const voices = synth.getVoices();
      setVoicesOptions(voices);
    };

    if (!synth.getVoices().length) {
      synth.addEventListener('voiceschanged', handleVoicesChange);
    } else {
      handleVoicesChange();
    }
    return () => {
      synth.removeEventListener('voiceschanged', handleVoicesChange);
    };
  }, []);

  // 筛选中文语言包排在前头，其他语言包排在后头
  const sortCnVoiceOptions = useMemo(() => {
    return [
      ...voicesOptions.filter((voice) => voice.lang.startsWith('zh')),
      ...voicesOptions.filter((voice) => !voice.lang.startsWith('zh')),
    ];
  }, [voicesOptions]);

  return {
    voicesOptions,
    sortCnVoiceOptions,
  };
};
