import { PrimaryKey } from '@erp-mobile/types';
import axios from 'axios';
import { isArray } from 'lodash-es';
import {
  GetTaskParams,
  TransferOutboundTaskDto,
  TransferParams,
} from './transfer-outbound.dto';

/**
 * 调拨出库
 */
export class TransferOutboundService {
  /**
   * 获取任务
   */
  async getTask(params: GetTaskParams) {
    const { data } = await axios.post<TransferOutboundTaskDto>(
      'api/outbound/transfer_tasks',
      params,
    );
    return data;
  }

  /**
   * 获取任务
   */
  async getTaskInfo() {
    const { data } = await axios.get<TransferOutboundTaskDto>(
      'api/outbound/transfer_tasks',
    );
    return data;
  }

  /**
   * 调拨出库
   */
  async transfer({ id, ...params }: TransferParams) {
    const { data } = await axios.post(
      `api/outbound/transfer_tasks/${id}`,
      params,
    );
    return data;
  }

  /**
   * 扫码sku可能是json字符串，需要做parse后返回数组
   */
  parseSku(sku: string) {
    try {
      const res = JSON.parse(sku);
      return isArray(res) ? res : [sku];
    } catch (error) {
      return [sku];
    }
  }

  /**
   * 异常出库
   */
  async abnormal(id: PrimaryKey, params: { sku_id: PrimaryKey }) {
    await axios.post(`api/outbound/transfer_tasks/${id}/sku/abnormal`, params);
  }
}

export const transferOutboundService = new TransferOutboundService();
