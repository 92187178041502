import { FC, useEffect } from 'react';

import { useAutoClearProfile, useLogout } from '@/features/user';
import {
  ClientError,
  HttpError,
  HttpServerError,
  UnauthenticatedError,
} from '@erp-mobile/core';
import { OverlayProvider, useRedirectUrl } from '@erp-mobile/hooks';
import { ErrorBlock } from 'antd-mobile';
import { Navigate, useRouteError } from 'react-router-dom';
import Authorization from './authorization/authorization';

export const ErrorFallback: FC = () => {
  const logout = useLogout();
  const redirectUrl = useRedirectUrl();
  useAutoClearProfile();
  const error = useRouteError();

  useEffect(() => {
    if (error instanceof UnauthenticatedError) {
      Promise.reject(error);
    } else {
      console.error(error);
    }
  }, [error]);

  if (error instanceof UnauthenticatedError) {
    logout();
    return (
      <Navigate
        to={`/user/login?from=${redirectUrl}`}
        // 添加 unauthenticated 状态，以便能够通过 login 页面进行错误提示
        // WHY: ErrorBoundary 的 Fallback 会被渲染多次，则导致如果在这个地方进行错误提示的
        // 话，也会出现多个错误提示
        state={{ unauthenticated: true }}
      />
    );
  }

  if (error instanceof HttpServerError) {
    return (
      <ErrorBlock
        title="服务器开小差了"
        description="请稍后再试，或者联系管理员"
      ></ErrorBlock>
    );
  }

  if (error instanceof ClientError) {
    return (
      <ErrorBlock
        title="客户端发生未知错误"
        description={error.message}
      ></ErrorBlock>
    );
  }

  if (error instanceof HttpError) {
    return (
      <ErrorBlock title="发生未知错误" description={error.message}></ErrorBlock>
    );
  }

  return <ErrorBlock title="发生未知错误" />;
};

/**
 * 路由拦截
 * 功能
 *  1. 检查授权
 *  2. 全局依赖
 * @returns React.ReactNode
 */
export default function Layouts() {
  return (
    <OverlayProvider>
      <Authorization></Authorization>
    </OverlayProvider>
  );
}
