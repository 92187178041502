import {
  useBroadcastSettings,
  useLanguageSettings,
} from '@/features/broadcast';
import { BooleanNumber } from '@erp-mobile/types';

const mp3Url: Record<string, string> = {
  失败: 'assets/audio/lose.mp3',
  成功: 'assets/audio/successful.mp3',
  完成: 'assets/audio/complete.mp3',
  错误: 'assets/audio/error.mp3',
  称重: 'assets/audio/weigh.mp3',
  备注: 'assets/audio/haveDesc.mp3',
};

/**
 * 自定义音频输出
 * @param text 输出内容
 * @param rate 语速，默认2倍速
 */
export const useBaseSpeak = () => {
  const [settings] = useLanguageSettings();
  const { data: broadcastData } = useBroadcastSettings();

  const synth = window.speechSynthesis;

  const onSpeak = (text: string, voicesName?: string) => {
    // 是否mp3播报
    if (!settings.is_synthesis && mp3Url[text]) {
      playAudio(mp3Url[text]);
      return;
    }

    // 兼容低版本浏览器
    if (window.SpeechSynthesisUtterance === undefined) {
      return;
    }

    // 语言包错误时会导致speechSynthesis.pending一直为true需要先停止之前的播报
    speechSynthesis.cancel();
    const utterance = new SpeechSynthesisUtterance(text);

    const languagename = voicesName ?? settings.voices;
    //  语言包处理
    const voices = synth.getVoices();
    if (languagename && voices.find((item) => item.name === languagename)) {
      const voice = voices.find(
        (item) => item.name === languagename,
      ) as SpeechSynthesisVoice;
      utterance.voice = voice;
      utterance.lang = voice.lang;
    }
    utterance.onerror = (event) => {
      console.error('语音合成错误：', event);
    };
    utterance.rate = broadcastData?.speed ?? 2;

    speechSynthesis.speak(utterance);
  };

  return {
    speak: (text: string, voicesName?: string) => {
      onSpeak(text, voicesName);
    },
    speakSuccess: (voicesName?: string) => {
      onSpeak('成功', voicesName);
    },
    speakFailure: (voicesName?: string) => {
      onSpeak('失败', voicesName);
    },
    speakRemark: (remark?: string) => {
      const desc = settings.is_synthesis
        ? remark || '成功'
        : remark
        ? '备注'
        : '成功';

      onSpeak(broadcastData?.switch === BooleanNumber.True ? desc : '成功');
    },
    testSpeak: (voicesName: string) => {
      speechSynthesis.cancel();
      const utterance = new SpeechSynthesisUtterance('成功');

      const languagename = voicesName ?? settings.voices;
      //  语言包处理
      const voices = synth.getVoices();
      if (languagename && voices.find((item) => item.name === languagename)) {
        const voice = voices.find(
          (item) => item.name === languagename,
        ) as SpeechSynthesisVoice;
        utterance.voice = voice;
        utterance.lang = voice.lang;
      }
      utterance.rate = broadcastData?.speed ?? 2;
      speechSynthesis.speak(utterance);
    },
    /**
     * 启动 audio 播放
     * why:
     *  axios 请求异步导致无法触发语音播报，触发：Failed to execute 'play' on 'HTMLMediaElement': API can only be initiated by a user gesture.
     * 解决：
     *  需要在请求前先静音播放一次，第二次后能正常 play
     *
     * -- 使用 AudioContext 方法替换
     */
    // steupAudio: () => {
    //   // 使用语音合成
    //   if (settings.is_synthesis) {
    //     return;
    //   }

    //   const audioElement = document.getElementById(
    //     'broadcast_audio',
    //   ) as HTMLAudioElement;

    //   if (!audioElement) {
    //     return;
    //   }

    //   if (audioElement.getAttribute('setup') === 'true') {
    //     return;
    //   }

    //   audioElement.setAttribute('src', mp3Url['成功']);
    //   audioElement.volume = 0;
    //   audioElement.play();
    //   audioElement.setAttribute('setup', 'true');
    // },
  };
};

/**
 * 自定义音频输出
 * @param text 输出内容
 * @param rate 语速，默认2倍速
 */
export const speak = (text: string, rate?: number) => {
  // 兼容低版本浏览器
  if (window.SpeechSynthesisUtterance === undefined) {
    return;
  }

  const utterance = new SpeechSynthesisUtterance(text);
  utterance.rate = rate ?? 2;
  speechSynthesis.speak(utterance);
};

const AudioContext =
  window.AudioContext ||
  (window as any).webkitAudioContext ||
  (window as any).mozAudioContext ||
  (window as any).msAudioContext;

// 创建音频上下文
const audioContext = new AudioContext();

/**
 * 使用 AudioContext 播放 mp3
 * @param url 音频地址
 */
export function playAudio(url: string) {
  fetch(url)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => {
      // 解码获取的二进制数据
      audioContext.decodeAudioData(
        arrayBuffer,
        function (audioBuffer) {
          // 创建音频源
          const audioSource = audioContext.createBufferSource();
          audioSource.buffer = audioBuffer;

          // 连接音频源到输出
          audioSource.connect(audioContext.destination);

          // 播放音频
          audioSource.start();
        },
        function (err) {
          console.error('解码失败', err);
        },
      );
    })
    .catch((error) => {
      console.error('请求失败', error);
    });
}
